import { useState } from 'react'
import { assetsBaseURL, daysOfWeek, extractSelectedDay, extractSelectedMonth, extractSelectedOffer, extractSelectedSeason, homeAwayOptions, initialScheduleState, monthsOptions, offers, seasonOptions } from '../models/staticData'
import { EScheduleActionTypes, TSeasonTypes } from '../../../../etc/types'
import { useScheduleContext } from '../components/etc/ScheduleContext'
import { processDayOfWeek, processMonthOfYear, processOffer, sortData } from '../components/etc/functions'

const FilterV2: React.FC<{}> = (props) => {

    const { state, dispatch } = useScheduleContext()
    const { weekDaysChecked, filteredScheduleData, selectedTeam, homeOrAwayGame, seasonTypes, monthsChecked, dateRange, teamList: fullTeamList, offersChecked } = state

    //Filter Show/Hide for filter dropdowns
    // const [homeAwayVisible, sethomeAwayVisible] = useState(false);
    const [dayVisible, setdayVisible] = useState(false);
    const [monthVisible, setmonthVisible] = useState(false);
    const [offersVisible, setoffersVisible] = useState(false);
    const [opponentsVisible, setopponentsVisible] = useState(false)
    const [seasonTypeVisible, setseasonTypeVisible] = useState(false)

    const weekDaysCheckedAsString = JSON.stringify(Array.from(weekDaysChecked.values()))
    const weekDaysCheckedAsStringDefault = "[]"

    const monthsCheckedAsString = JSON.stringify(Array.from(monthsChecked.values()))
    const monthsCheckedAsStringDefault = "[]"

    const offersCheckedAsString = JSON.stringify(Array.from(offersChecked.values()))
    const offersCheckedAsStringDefault = "[]"

    const isAnyFilterActive = selectedTeam === initialScheduleState.selectedTeam &&
        // seasonTypes === initialScheduleState.seasonTypes &&
        weekDaysCheckedAsString === weekDaysCheckedAsStringDefault &&
        monthsCheckedAsString === monthsCheckedAsStringDefault &&
        offersCheckedAsString === offersCheckedAsStringDefault &&
        seasonTypes === initialScheduleState.seasonTypes


    return (
        <section className='ticketFinder globalContainer'>
            {/* removed per catrina request */}
            {/* <div className='ticketFinderTitle'>
                <img alt="print icon" src={assetsBaseURL + "MG_Icons_Tickets.svg"}></img>
                <h2> Ticket Finder </h2>
            </div> */}
            <div className='activeBtnPlacement'>
                {!isAnyFilterActive ?
                    <button className="filter-active"
                        onClick={(e) =>
                            dispatch({
                                type: EScheduleActionTypes.reset_filters
                            })
                        }
                    >  <h6> clear filter </h6>  <img className="filter-icon" alt="exit icon" src={assetsBaseURL + "MG_Icons_X_Exit_dark_.svg"} />
                    </button>
                    : null}
            </div>
            <div className='ticketFinderFilter'>

                {/* Opponents Filter */}
                <fieldset className='filterItemContainer'
                    onClick={() => setopponentsVisible(!opponentsVisible)}>
                    <div className="filterLabelContainer" role="button" id='homeoraway'>
                        <h5 className='staticLabel'> Opponent:</h5>
                        <h5 className='selectedLabel'>{selectedTeam}</h5>
                    </div>
                    {opponentsVisible && (
                        <ul className='dropdown'>
                            {["all", ...fullTeamList].map((item) =>
                                <li className={selectedTeam === item ? 'active' : ""}
                                    key={item}
                                    id={item}>
                                    <button
                                        onClick={(e) => dispatch({
                                            type: EScheduleActionTypes.selected_team,
                                            value: item
                                        })}
                                    > {item} </button>
                                </li>
                            )}
                        </ul>
                    )}
                </fieldset>

                {/* Home/Away Filter: Hidden as single game is home only */}
                {/* <fieldset className='filterItemContainer'
                    onClick={() => sethomeAwayVisible(!homeAwayVisible)}>
                    <div className="filterLabelContainer" role="button" id='homeoraway'>
                        <h5 className='staticLabel'> Home/Away:</h5>
                        <h5 className='selectedLabel'>{homeOrAwayGame}</h5>
                    </div>
                    {homeAwayVisible && (
                        <ul className='dropdown'>
                            {homeAwayOptions.map((item) =>
                                <li className={homeOrAwayGame === item ? 'active' : ""}
                                    key={item}
                                    id={item}>
                                    <button
                                        onClick={(e) => dispatch({
                                            type: EScheduleActionTypes.set_home_or_away_games,
                                            value: item
                                        })}
                                    > {item} </button>
                                </li>
                            )}
                        </ul>
                    )}
                </fieldset> */}

                {/* Days of Week Filter */}
                <fieldset className='filterItemContainer'
                    onClick={() => setdayVisible(!dayVisible)}>
                    <div className="filterLabelContainer" role="button" id='homeoraway'>
                        <h5 className='staticLabel'> Day:</h5>
                        <h5 className='selectedLabel'> {extractSelectedDay(weekDaysChecked)} </h5>
                    </div>
                    {dayVisible && (
                        <ul className='dropdown'>
                            {[{ key: -1, text: "all" }, ...daysOfWeek].map((item) =>
                                <li className={weekDaysChecked.get(item.key) ? 'active' : ""}
                                    key={item.key}
                                    id={item.text}>
                                    <button
                                        onClick={(e) => dispatch({
                                            type: EScheduleActionTypes.save_week_days,
                                            value: processDayOfWeek(weekDaysChecked, item.key)
                                        })}
                                    > {item.text}</button>
                                </li>
                            )}
                        </ul>
                    )}
                </fieldset>

                {/* Month Filter */}
                <fieldset className='filterItemContainer'
                    onClick={() => setmonthVisible(!monthVisible)}>
                    <div className="filterLabelContainer" role="button" id='homeoraway'>
                        <h5 className='staticLabel'> Month:</h5>
                        <h5 className='selectedLabel'> {extractSelectedMonth(monthsChecked)} </h5>
                    </div>
                    {monthVisible && (
                        <ul className='dropdown'>
                            {[{ key: -1, text: "all" }, ...monthsOptions].map((item) =>
                                <li className={monthsChecked.get(item.key) ? 'active' : ""}
                                    key={item.key}
                                    id={item.text}>
                                    <button
                                        onClick={(e) => dispatch({
                                            type: EScheduleActionTypes.save_months,
                                            value: processMonthOfYear(monthsChecked, item.key)
                                        })}
                                    > {item.text}</button>
                                </li>
                            )}
                        </ul>
                    )}
                </fieldset>

                {/* Offers Filter */}
                <fieldset className='filterItemContainer'
                    onClick={() => setoffersVisible(!offersVisible)}>
                    <div className="filterLabelContainer" role="button" id='homeoraway'>
                        <h5 className='staticLabel'>Promotion:</h5>
                        <h5 className='selectedLabel'>{extractSelectedOffer(offersChecked)}</h5>
                    </div>
                    {offersVisible && (
                        <ul className='dropdown'>
                            {[{ key: -1, text: "all" }, ...offers].map((item) =>
                                <li className={offersChecked.get(item.key) ? 'active' : ""}
                                    key={item.key}
                                    id={item.text}>
                                    <button
                                        onClick={(e) => {
                                            if (undefined === item.key) {
                                                return
                                            }
                                            dispatch({
                                                type: EScheduleActionTypes.set_offers,
                                                value: processOffer(offersChecked, item.key)
                                            })
                                        }
                                        }
                                    > {item.text} </button>
                                </li>
                            )}
                        </ul>
                    )}
                </fieldset>
                
                {/* IST Filter */}
                <fieldset className='filterItemContainer'
                    onClick={() => setseasonTypeVisible(!seasonTypeVisible)}>
                    <div className="filterLabelContainer" role="button" id='seasonType'>
                        <h5 className='staticLabel'> Season Type:</h5>
                        <h5 className='selectedLabel'>{extractSelectedSeason(seasonTypes)}</h5>
                    </div>
                    {seasonTypeVisible && (
                        <ul className='dropdown'>
                            {seasonOptions.map(([key, value]) =>
                                <li className={seasonTypes === key ? 'active' : ""}
                                    key={key}
                                    id={key}>
                                    <button
                                        onClick={(e) =>  dispatch({
                                            type: EScheduleActionTypes.set_seasontype,
                                            value: key as TSeasonTypes
                                        })}
                                    > {value} </button>
                                </li>
                            )}
                        </ul>
                    )}
                </fieldset>
                

            </div>
        </section >
    )
}

export default FilterV2
