import { SetStateAction, useEffect, useState } from "react"
import { apiBaseURL, assetsBaseURL, daysOfWeek, fetchSettings, initialScheduleState } from "../../models/staticData"
import { EScheduleActionTypes, ICAPISchedule, ICAPITeam, ISCheduleDataRes, ITeam, ITransformedElement, ItransformSpreadsheetData, TContentAPIVideos, THomeOrAwayOptions, TScheduleActions, TScheduleState } from "../../../../../etc/types"
import { DateTime } from "luxon"
import { grizzliesTeamId } from "../../../../../etc/settings"

const fetch = require('fetch-retry')(global.fetch)

export function nbaSeasons() {
  let currentSeasonYear = DateTime.now().year
  const currentMonth = DateTime.now().month

  //to account for a split year season: we are targetting month 8 "August 01" as our "new season year flip". Any month that is before the "new" schedule season/year or less than 8 will pull information from the previous season. This way once we are in the second half of the season or in the new year - our schedule remains until the next august.
  if (currentMonth < 7) {
    currentSeasonYear -= 1
  }
  const nbaSeasons = []
  for (let i = 0; i < 2; i++) {
    nbaSeasons.push(currentSeasonYear - i)
  }
  return nbaSeasons
}

export const formatNbaDate = (dateString: string) => {
  const nbaDate = DateTime.fromISO(dateString)
  return nbaDate.toFormat("LLL d")
}
export const formatTime = (timeString: string, timezone: string) => {
  const nbaESTTime = DateTime.fromISO(timeString, { zone: timezone })
  const nbaCSTTime = nbaESTTime.setZone("America/Chicago")

  return nbaCSTTime.toFormat("h:mm a ZZZZ")
}

export const useCAPISchedule = (year: number) => {
  const [data, setData] = useState<ISCheduleDataRes[]>([])
  useEffect(() => {
    let ignore = false
    fetch(`${apiBaseURL}public/1/teams/${grizzliesTeamId}/schedule?season=${convertToSeasonString(year)}`)
      .then((response: any) => response.json())
      .then((json: { results: { schedule: ICAPISchedule[] } }) => {
        if (!ignore) {
          const convertData = convertScheduleData(json.results.schedule)
          setData(uniqueByDate(convertData))
        }
      })
    return () => {
      ignore = true
    }
  }, [year])
  return data
}

//Solves for Preseason Data duplication once feed is updated
function uniqueByDate(array: any[]) {
  // Create a Set to store unique dates
  const seenDates = new Set();

  // Filter the array to remove objects with duplicate dates
  return array.filter(item => {
    // Extract the date portion from the utcTime string
    const date = item.etm.split('T')[0];

    // Check if the date has already been seen
    if (seenDates.has(date)) {
      return false; // If the date has been seen, filter out this item
    }

    // Otherwise, add the date to the Set and keep this item
    seenDates.add(date);
    return true;
  });
}

export function filterData(nbaSeasonData: ISCheduleDataRes[], state: TScheduleState)
  : ISCheduleDataRes[] {

  const { upcomingOrPast, year, selectedTeam, homeOrAwayGame, seasonTypes, dateRange, offersChecked, googleSpreadsheetData } = state

  const currentDate = DateTime.local().setZone("America/Chicago")
  //Based on the value of "upcomingOrPast" if it is true, we will filter games that happen today or in the future. When it is false we will return only past games
  let filtered = nbaSeasonData.filter(item => {

    const ESTDateTimeObject = DateTime.fromISO(item.etm).setZone("America/New_York")
    const CSTDateTimeObject = ESTDateTimeObject.setZone("America/Chicago")

    //Here we added a secondary check, if the year is >= to the current season aka 2023+ he will render the "future" games as a default in the upcoming - results toggle
    if (upcomingOrPast && year >= nbaSeasons()[0]) {
      return CSTDateTimeObject >= currentDate
    } else {
      return CSTDateTimeObject < currentDate
    }
  })

  //Home/Away Filter
  if ("all" !== homeOrAwayGame) {
    filtered = filtered.filter(f => {
      if ("home" === homeOrAwayGame) {
        return "TN" === f.as
      } else {
        return "TN" !== f.as
      }
    })
  }

  //Days Filter
  if (state.weekDaysChecked.size) {
    filtered = filtered.filter(f => {
      const gameWeekDay = DateTime.fromISO(f.gdte).weekday

      //Our daysOfWeek starts with Monday index 0, however Luxon starts with day one indexed as 1. So we are decreasing by 1 day
      return undefined === state.weekDaysChecked.get(gameWeekDay - 1) ? false : true
    })
  }

  //Offers Filter
  //When the offers filter is checked we begin this logic
  if (offersChecked.size) {
    filtered = filtered.filter(game => {
      //if the game has ended and filter is toggled the game is not shown
      if (3 === Number(game.st)) {
        return false
      }
      //if there is nothing denoted in google spreadshit nothing will be filtered - manual tagging on our end
      const googleRecord = googleSpreadsheetData.filter(g => game.gdte === g.date)
      if (!googleRecord.length) {
        return false
      }
      //This was accounting for multiselect but it is no longer needed
      // if (0 === offersChecked.get(0) && 1 === offersChecked.get(1)) {
      //   return googleRecord[0].giveaways !== "" || googleRecord[0].themenight !== ""
      // }

      if (0 === offersChecked.get(0)) {
        return googleRecord[0].giveaways !== ""
      }
      if (1 === offersChecked.get(1)) {
        return googleRecord[0].themenight !== ""
      }

    })
  }

  //Months Filter
  if (state.monthsChecked.size) {
    filtered = filtered.filter(f => {
      const month = DateTime.fromISO(f.gdte).month

      //Our monthsChecked starts with Monday index 0, however Luxon starts with day one indexed as 1. So we are decreasing by 1 day
      return undefined === state.monthsChecked.get(month - 1) ? false : true
    })
  }

  //Teams/Opps Filter
  if ("all" !== selectedTeam) {
    filtered = filtered.filter(f => {
      const isHomeGame = "TN" === f.as
      const opponentKey = !isHomeGame ? "h" : "v"
      return selectedTeam === f[opponentKey].tn

    })
  }

  //Season Types (Only being used in filter V1)
  if ("all" !== seasonTypes) {
    filtered = filtered.filter(f => f.seasonType === seasonTypes)
  }

  //date range for url query parameters (hidden feature - not currently reflected in the UI 12/8/23)
  // Use Case: schedule?date_range=2024-2-4|2024-2-16,2023-12-8 (Date Range will accept a range of dates or a singular date)
  if ("" !== dateRange) {
    const collection = dateRange.split(",").map(c => {
      let split = c.split("|")
      if (split.length === 1) {
        split.push(split[0])
      }
      // This block will convert the following string '2023-12-8' to '2023-12-08', i.e.
      // if day or month is a single digit, it will add a leading zero, so we can use
      // lexicographical comparison correctly, because the following statement:
      // '2024-2-4' <= '2024-2-29' wil return false otherwise
      split = split.map(s => {
        const [y, m, d] = s.split('-')
        return `${y}-${chkLeadingZero(m)}-${chkLeadingZero(d)}`
      })
      return split
    })

    filtered = filtered.filter(f => collection.find(c => f.gdte >= c[0] && f.gdte <= c[1])
    )
  }

  return filtered
}

export const chkLeadingZero = (str: string): string => 1 === str.length ? '0' + str : str

export const areAnyGamesCompleted = (games: ISCheduleDataRes[]): boolean => Boolean(games.filter(g => g.st === "3").length)

export const getSeasonTypeDescription = (key: string): string => {

  const vocab = new Map()

  vocab
    .set("preseason", "Preseason")
    .set("season", "Regular Season")
    .set("in-season", "NBA Emirates Cup")
    .set("play-in", "Play In")
    .set("playoff", "Playoff")
    .set("summer-league", "Summer League")

  return vocab.get(key)

}

export const sortData = (items: any, key: string) => {

  // sort by name
  items
    .sort((a: any, b: any) => {
      const nameA = a[key]
      const nameB = b[key]
      if (nameA > nameB) {
        return -1
      }
      if (nameA < nameB) {
        return 1
      }

      // names must be equal
      return 0
    })

  return items.map((i: { [x: string]: string }) => {
    i["value"] = i[key]
    i["stat"] = key
    return i
  })

}

//Google Spreadsheet Data transform
export const transformSpreadsheetData = (item: any[]): ItransformSpreadsheetData => {

  const tpl = {
    title: "",
    link: ""
  }

  return {
    "date": item[0] ?? "",
    "time": item[1] ?? "",
    "opponent": item[2] ?? "",
    "city": item[3] ?? "",
    "team": item[4] ?? "",
    "ticket": item[5] ?? "",
    "giveaways": item[6] ?? "",
    "themenight": item[12] ?? "",
    "sponsorLogo": item[13] ?? "",
    "uniform": item[14] ? arrToObject(item[14].split("#")) : tpl,
  }

}
//Google Spreadsheet helper function
const arrToObject = (item: any): ITransformedElement => {
  return {
    "title": item[0],
    "link": assetsBaseURL + item[1]
  }

}

export const scheduleReducer = (_state: TScheduleState, action: TScheduleActions): TScheduleState => {

  // To set activeVideo only once and specify action types
  // rather than repeat activeVideo for every action type
  let state = { ..._state }

  switch (action.type) {
    case EScheduleActionTypes.save_week_days:
    case EScheduleActionTypes.selected_team:
    case EScheduleActionTypes.set_home_or_away_games:
    case EScheduleActionTypes.set_seasontype:
    case EScheduleActionTypes.save_months:
      state = {
        ...state,
        activeVideo: initialScheduleState.activeVideo
      }
  }

  switch (action.type) {

    case EScheduleActionTypes.show_upcoming_or_past:
      return {
        ...state,
        upcomingOrPast: action.value
      }
    case EScheduleActionTypes.set_year:
      return {
        ...resetFilters(state),
        year: action.value,
        upcomingOrPast: nbaSeasons()[0] === action.value
      }
    case EScheduleActionTypes.save_week_days:
      return {
        ...state,
        weekDaysChecked: action.value
      }
    case EScheduleActionTypes.set_filtered_schedule_data:
      return {
        ...state,
        filteredScheduleData: action.value,
        firstGameId: Number(action.value[0]?.gid)
      }
    case EScheduleActionTypes.selected_team:
      return {
        ...state,
        selectedTeam: action.value
      }
    case EScheduleActionTypes.set_home_or_away_games:
      return {
        ...state,
        homeOrAwayGame: action.value,
      }
    case EScheduleActionTypes.set_seasontype:
      return {
        ...state,
        seasonTypes: action.value,
      }
    case EScheduleActionTypes.set_active_video:
      return {
        ...state,
        activeVideo: action.value
      }
    case EScheduleActionTypes.reset_filters:
      return resetFilters(state)

    case EScheduleActionTypes.set_google_spreadsheet_data:
      return {
        ...state,
        googleSpreadsheetData: action.value
      }
    default:
      return state
    case EScheduleActionTypes.save_months:
      return {
        ...state,
        monthsChecked: action.value,
      }
    case EScheduleActionTypes.set_date_range:
      return {
        ...state,
        dateRange: action.value
      }
    case EScheduleActionTypes.set_team_list:
      return {
        ...state,
        teamList: action.value
      }
    case EScheduleActionTypes.set_offers:
      return {
        ...state,
        offersChecked: action.value
      }
    case EScheduleActionTypes.set_layout_type:
      return {
        ...state,
        layoutType: action.value
      }
  }

}

const resetFilters = (state: TScheduleState): TScheduleState => {
  return {
    ...state,
    seasonTypes: initialScheduleState.seasonTypes,
    selectedTeam: initialScheduleState.selectedTeam,
    weekDaysChecked: new Map(),
    monthsChecked: new Map(),
    dateRange: "",
    offersChecked: new Map(),
  }
}

//For V1 Filter Checkbox/Multiselector
export const processDaysOfWeek = (haystack: Map<any, any>, needle: number): Map<any, any> => {

  const weekendsDays = [5, 6]

  if (undefined !== haystack.get(needle)) {

    if (weekendsDays.includes(needle)) {
      haystack.delete(7)
      haystack.delete(needle)
    } else if (7 === needle) {
      haystack.delete(7)
      weekendsDays.forEach(day => haystack.delete(day))
    } else {
      haystack.delete(needle)
    }
  } else {
    haystack.set(needle, needle)
    if (haystack.has(5) && haystack.has(6)) {
      haystack.set(7, 7)
    } else if (7 === needle) {
      weekendsDays.forEach(day => haystack.set(day, day))
    }
  }
  return haystack
}
//For V2 Filter (Single selection)
export const processDayOfWeek = (haystack: Map<any, any>, needle: number): Map<any, any> => {

  const output = new Map()
  //-1 === 'all'
  if ([-1].includes(needle)) {
    return output
  }
  if ([7].includes(needle)) {
    [5, 6, 7].forEach(item => output.set(item, item))
    return output
  }
  output.set(needle, needle)
  return output
}

//For V2 Filter (Single selection)
export const processMonthOfYear = (haystack: Map<any, any>, needle: number): Map<any, any> => {

  const output = new Map()
  //-1 === 'all'
  if ([-1].includes(needle)) {
    return output
  }
  output.set(needle, needle)
  return output
}

//For V1 Filter Checkbox Selector
export const processMonthsOfYear = (haystack: Map<any, any>, needle: number): Map<any, any> => {

  if (undefined !== haystack.get(needle)) {
    haystack.delete(needle)
  } else {
    haystack.set(needle, needle)
  }
  return haystack
}


//For V2 Filter (Multi Select - not in use)
export const processOffers = (haystack: Map<any, any>, needle: number): Map<any, any> => {
  if (undefined !== haystack.get(needle)) {
    haystack.delete(needle)
  } else {
    haystack.set(needle, needle)
  }
  return haystack
}

//For V2 Filter (Single Select)
export const processOffer = (haystack: Map<any, any>, needle: number): Map<any, any> => {

  const output = new Map()
  //-1 === 'all'
  if ([-1].includes(needle)) {
    return output
  }
  output.set(needle, needle)
  return output

}
//do We need?
export const getHash = (haystack: Map<any, any>): string => {
  let output = ""
  haystack.forEach(item => {
    output += daysOfWeek.find(i => i.key === item)?.text
  })
  return output
}
//do We need?
export const getHomeOrAwayValueByKey = (index: number): THomeOrAwayOptions => {
  return 0 === index ? "home" : "away"
}

export const buildTeamsList = (schedule: ISCheduleDataRes[], fallbackTeam: string, getTeamsOnly = false): string[] => {
  const teamNames: string[] = []

  schedule.forEach(s => {
    const isHomeGame = "TN" === s.as
    const opponentKey = !isHomeGame ? "h" : "v"

    if ('Grizzlies' !== s[opponentKey].tn
      && !teamNames.includes(s[opponentKey].tn)) {
      teamNames.push(s[opponentKey].tn)
    }

  })
  if (!teamNames.length) {
    teamNames.push(fallbackTeam)
  }
  teamNames.sort()

  if (getTeamsOnly) {
    return teamNames
  }

  const allTeams = "all"

  !teamNames.includes(allTeams) && teamNames.unshift(allTeams)

  return teamNames
}

export const useContentAPI = (gameId: string) => {
  const [data, setData] = useState<TContentAPIVideos[]>([])

  useEffect(() => {
    let ignore = false
    fetch(`${apiBaseURL}public/1/leagues/nba/teams/${grizzliesTeamId}/content?types=video&games=${gameId}`, fetchSettings)
      .then((response: any) => response.json())
      .then((json: { results: { items: SetStateAction<TContentAPIVideos[]> } }) => {
        if (!ignore) {
          setData(json.results.items)
        }
      })
    return () => {
      ignore = true
    }
  }, [gameId])
  return data
}

const cdnPattern = /https:\/\/[^/]+\//
export const rewriteCDNURL = (link: string): string => link.replace(cdnPattern, apiBaseURL)

export function convertToSeasonString(year: number) {
  const nextYear = (year % 100) + 1
  return `${year}-${nextYear}`
}

function convertScheduleData(apiSchedules: ICAPISchedule[]): ISCheduleDataRes[] {

  return apiSchedules.map(apiSchedule => {
    const seasonType = getSeasonType(apiSchedule)
    const scheduleData: ISCheduleDataRes = {
      gid: apiSchedule.gid,
      gdte: getGTDE(apiSchedule.utcTime),
      etm: apiSchedule.easternTime.slice(0, -1),
      gameSubtype: apiSchedule.gameSubtype,
      an: apiSchedule.arenaName,
      ac: apiSchedule.arenaCity,
      as: apiSchedule.arenaState,
      st: apiSchedule.state.toString(),
      stt: apiSchedule.title,
      h: convertTeam(apiSchedule.home, apiSchedule),
      v: convertTeam(apiSchedule.visitor, apiSchedule),
      bd: {
        b: apiSchedule.providers.map(provider => ({
          broadcasterId: provider.broadcasterId,
          disp: provider.broadcasterDisplay,
          scope: provider.broadcasterScope,
          type: provider.broadcasterMedia,
        })),
      },
      seasonType: seasonType,
      seasonYear: apiSchedule.seasonYear
    }

    return scheduleData
  })
}

function convertTeam(apiTeam: ICAPITeam, apiSchedule: ICAPISchedule): ITeam {
  return {
    tid: apiTeam.tid,
    re: apiTeam.wins + '-' + apiTeam.losses,
    ta: apiTeam.ta,
    tn: apiTeam.tn,
    tc: apiTeam.tc,
    s: apiTeam.score.toString(),
    istGroup: apiSchedule.seriesText,
    leaders: apiTeam.leaders
  }
}

function getGTDE(inputString: string): string {
  // Parse the input string
  const dateTime = DateTime.fromISO(inputString, { zone: 'utc' })

  // Set the time zone to Central Time
  const centralTimeDateTime = dateTime.setZone('America/Chicago')

  // Format the DateTime object to the desired output format
  const outputString = centralTimeDateTime.toFormat('yyyy-MM-dd')
  return outputString
}

function getSeasonType(apiSchedule: ICAPISchedule): string {

  if ("0" !== apiSchedule.gid[0]) {
    return "summer-league"
  }
  if ("" !== apiSchedule.gameSubtype) {
    return apiSchedule.gameSubtype
  }

  const regex = /0/g
  const gameTypeNumber = apiSchedule.gid.replace(regex, "")[0]

  switch (gameTypeNumber) {
    case '1':
      return 'preseason'
    case '4':
      return 'playoff'
    case '5':
      return 'play-in'
    case '6':
      return 'ist-final'
    default:
      return 'season'
  }
}


export function useFetchData(url: string) {
  const [data, setData] = useState([])

  useEffect(() => {
    let ignore = false
    fetch(url, fetchSettings)
      .then((response: any) => response.json())
      .then((json: { values: SetStateAction<never[]> }) => {
        if (!ignore) {
          setData(json.values)
        }
      }
      )
    return () => {
      ignore = true
    }
  }, [url])
  return data
}

export const checkIfSummerLeague = (game: ISCheduleDataRes): boolean => {
  const gameMonth = DateTime.fromISO(game.gdte).month
  // Summer League months are June (rarely) and July
  return gameMonth === 7
}
