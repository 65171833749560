import { IFutureGame, ISCheduleDataRes, ItransformSpreadsheetData } from "../../../../etc/types"
import { allowedBroadcastScopes, assetsBaseURL, buttonOverride, getBroadcasters, metaDataTemplate } from "../models/staticData"
import DateTimeColumn from "./DateTimeColumn"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useScheduleContext } from "../components/etc/ScheduleContext"
import React from "react"

const FutureGame: React.FC<IFutureGame> = (props) => {

    const { state } = useScheduleContext()

    const { googleSpreadsheetData } = state

    const { e } = props

    const metaData =
        googleSpreadsheetData
            .filter(s => s.date === e.gdte)[0]
        ?? metaDataTemplate

    //for home games we are removing all providers with the scope set to away
    const providerKeyToRemove = e.as === "TN" ? "away" : "home"
    const scopeProviders = allowedBroadcastScopes(providerKeyToRemove)

    const tvBroadcasters = e.bd.b.filter(b => b.type === "tv" && scopeProviders.get(b.scope))
    const radioBroadcasters = e.bd.b.filter(b => b.type === "radio" && scopeProviders.get(b.scope))

    return (
        <>
            <DateTimeColumn {...{ e }} />
            {/* broadcast div */}
            <div className="gameBroadPromosContainer">
                <div className="gameBroadcast gDiv">
                    <h5 className="tuneInTitle_mobile"> Tune In  </h5>
                    <div className="tuneInBlock">
                        {/* TV Broadcasters */}
                        <div className="tv">
                            <h5> TV </h5>
                            <ul>
                                {tvBroadcasters.length
                                    ? tvBroadcasters
                                        .map(t =>
                                            <li
                                                className={t.disp}
                                                key={t.broadcasterId}
                                                value={t.broadcasterId}>
                                                <BroadcastersLogo
                                                    needle={t.disp} />
                                            </li>)

                                    :  null 
                                    // <li>
                                    //     <LazyLoadImage
                                    //         src={assetsBaseURL + "ballyballyplus.jpg"}
                                    //         alt="bally-sport-placeholder" />
                                    // </li>
                                    
                                    }
                            </ul>
                        </div>

                        {/* Radio Broadcasters */}
                        {<div className="listen">
                            <h5>Listen</h5>
                            <ul>
                                {radioBroadcasters
                                    .map(t =>
                                        <li
                                            className={t.disp}
                                            key={t.broadcasterId}
                                            value={t.broadcasterId}>
                                            <BroadcastersLogo
                                                needle={t.disp} />
                                        </li>)}
                                {!radioBroadcasters.length
                                    &&
                                    <li>
                                        <LazyLoadImage
                                            className="espnRadioFallbackImg"
                                            src={assetsBaseURL + "MEM_929ESPN_xtraBold.png"}
                                            alt="92.5 ESPN Memphis" />
                                    </li>}
                            </ul>
                        </div>
                        }
                    </div>

                    {/* Promotions Block*/}
                    {'' != metaData.giveaways ?
                        <div className="promoBlock">
                            <h5> Giveaway </h5>
                            <p> {metaData.giveaways}</p>
                        </div>
                        : null}
                </div>

                {/* Theme Night & Partner*/}
                {'' != metaData.themenight || metaData.sponsorLogo
                    && e.ac === "Memphis"
                    ?
                    <div className="gameThemePartner gDiv">
                        {'' != metaData.themenight ?
                            <h5 className="themeNightTitle"> {metaData.themenight} </h5>
                            : null}
                        {'' != metaData.sponsorLogo && '' != metaData.themenight ? <span className="lineBreak"></span> : null}
                        {'' != metaData.sponsorLogo ?
                            <div >
                                <h6>Presented by</h6>
                                <LazyLoadImage alt={metaData.sponsorLogo} src={assetsBaseURL + metaData.sponsorLogo} />
                            </div>
                            : null}
                    </div>
                    : null}

                {/* Showing Jerseys for home and away games */}
                {'' != metaData.uniform.title
                    ?
                    <div className="gameUniform gDiv">
                        <div className="jerseyImg">
                            <LazyLoadImage src={metaData.uniform.link} alt={metaData.uniform.title} />
                        </div>
                        <div className="uniTitle">  <h6> {metaData.uniform.title} </h6> </div>
                    </div>
                    : null}

            </div>

            {/* Ticket Button */}
            <GameCTAButtons {...{ e, metaData, buttonOverride }} />
        </>
    )
}

export default FutureGame


const GameCTAButtons: React.FC<{
    e: ISCheduleDataRes,
    metaData: ItransformSpreadsheetData,
    buttonOverride: React.CSSProperties
}>
    = ({ e, metaData, buttonOverride }) => {

        const ticketUrl = metaData.ticket
        const defaultUrl = "https://www.nba.com/grizzlies/fanduel-sports-network"

        const getButtonProps = () => {
            if (ticketUrl) {
                return { href: ticketUrl, className: "primaryBtn", buttonText: "find tickets" }
            }
            return { href: ticketUrl ? ticketUrl : defaultUrl, className: "secondaryBtn", buttonText: "tune in" }
        }

        const { href, className, buttonText } = getButtonProps()

        return (
            <div className="gameCTAButtons gDiv">
                <a className={className} href={href} target="_blank" rel="noreferrer">
                    <button style={buttonOverride}>{buttonText}</button>
                </a>
            </div>
        )
    }



//Getting logos from static data structure. Created a condition to set the image if it matches the static data OR if the image doesn't match just print the broadcasters name
const BroadcastersLogo: React.FC<{ needle: string }> = (props) => {
    const { needle } = props

    const broadcasters = getBroadcasters()
    const candidate: string = broadcasters.get(needle)

    return candidate ? <LazyLoadImage alt={needle} src={assetsBaseURL + candidate} /> : <p> {needle} </p>
}
