import { ReactNode, createContext, useContext, useReducer } from "react"
import { TScheduleContextType } from "../../../../../etc/types"
import { initialScheduleState } from "../../models/staticData"
import { scheduleReducer } from "./functions"

const ScheduleContext = createContext<TScheduleContextType>({
  state: { ...initialScheduleState },
  dispatch: () => { },
})

type Props = {
  children: ReactNode;
};
export function ScheduleContextProvider({ children }: Props) {
  const [state, dispatch] =
    useReducer(scheduleReducer, initialScheduleState);
  return (
    <ScheduleContext.Provider
      value={{
        state,
        dispatch,
      }} >
      {children}
    </ScheduleContext.Provider>
  );
}

export const useScheduleContext = () => 
useContext(ScheduleContext)