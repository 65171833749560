import React from "react"
import { EHomeKey, EScheduleActionTypes, ILeaderStats, IPastGame, TPlayerStatKeys } from "../../../../etc/types"
import { rewriteCDNURL, useContentAPI } from "../components/etc/functions"
import { assetsBaseURL, statDescription, swiperBreakpoints } from "../models/staticData"
import { useScheduleContext } from "../components/etc/ScheduleContext"
import DateTimeColumn from "./DateTimeColumn"

import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import "swiper/css"
import 'swiper/css/navigation'

import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

const PastGame: React.FC<IPastGame> = (props) => {

    const { e: game } = props
    const gameId = game.gid
    const isHomeGame = game.ac === "Memphis"

    const homeTeamKey: EHomeKey = isHomeGame ? EHomeKey.h : EHomeKey.v
    const { leaders } = game[homeTeamKey]
    const leaderKeys = Object.keys(leaders) as TPlayerStatKeys[]

    return (
        <>
            <DateTimeColumn {...{ e: game }} />
            <ResultsGameContainer
                {...{ gameId }} >
                {leaderKeys.map(idx =>
                    <LeaderStat
                        key={idx + gameId}
                        {...{ idx, p: leaders[idx] }}
                    />
                )}
            </ResultsGameContainer>
        </>
    )
}

export default PastGame

const ResultsGameContainer: React.FC<{ children?: React.ReactNode, gameId: string }> = (props) => {
    const { gameId } = props
    const gameVideos = useContentAPI(gameId).filter(f => f.videoAssets?.[1280]?.video && f.videoAssets?.[1280]?.thumbnail)

    const { state, dispatch } = useScheduleContext()
    const { activeVideo, firstGameId } = state

    return (<>
        <div className="resultsGameContainer">
            <div className="resultsGameLeaders gDiv">
                <h4> Game Leaders </h4>
                <div className="gameLeaderContainer">
                    {props.children}
                </div>
                <a className="primaryBtn" href={`https://www.nba.com/grizzlies/game/${gameId}`} target="_blank" rel="noreferrer"><button> game tracker </button> </a>
            </div>
            <div className="resultsVideos gDiv">
                <div className="videoTitle">
                    <h4> News & Highlights </h4>
                    <a className="gametrackerLink" href={`https://www.nba.com/grizzlies/game/${gameId}`} target="_blank" rel="noreferrer"> <h6>  Game Tracker</h6> <img src={assetsBaseURL + "MG_Icons_Arrow_NorthWest_black.svg"} alt="arrow icon"></img></a>
                </div>
                {gameVideos.length ?
                    <div className="swiper-outer">

                        <Swiper
                            className="mySwiper"
                            spaceBetween={15}
                            navigation={true}
                            modules={[Navigation]}
                            breakpoints={swiperBreakpoints}
                            watchSlidesProgress
                        >
                            {gameVideos.map((video, idx) => {
                                const videoURL = rewriteCDNURL(video.videoAssets?.[1280]?.video)

                                const videoThumbnail = rewriteCDNURL(video.videoAssets?.[1280]?.thumbnail)

                                return (
                                    <SwiperSlide
                                        key={video.id}>
                                        {video.id === activeVideo
                                            || -1 === activeVideo
                                            && (Number(gameId) === firstGameId && idx === 0) ?
                                            <div className="videoPlaceholder">
                                                <video
                                                    muted={-1 === activeVideo}
                                                    controls
                                                    preload="none"
                                                    autoPlay
                                                    playsInline
                                                    poster={videoThumbnail}
                                                >
                                                    <source
                                                        src={videoURL}
                                                        type="video/mp4" />
                                                    <p>Sorry, your browser does not support embedded videos, but do not worry, you can
                                                        <a href={videoURL}>download it</a>
                                                        and watch it with your favorite video player!</p>
                                                </video>
                                            </div>
                                            :
                                            <div className="videoImagePlaceholder"
                                                onClick={() =>
                                                    dispatch({
                                                        type: EScheduleActionTypes.set_active_video,
                                                        value: video.id
                                                    })
                                                }
                                            >
                                                <div>
                                                    <LazyLoadComponent>
                                                        <div className="playIcon">
                                                            <img src={assetsBaseURL + "playIcon.svg"} alt="play-icon" />
                                                        </div>
                                                        <LazyLoadImage
                                                            className="thumbnail"
                                                            effect="blur"
                                                            alt={video.title}
                                                            src={videoThumbnail}
                                                        />
                                                    </LazyLoadComponent>
                                                </div>
                                            </div>

                                        }
                                    </SwiperSlide>
                                )
                            }
                            )}
                            {/* </Swiper> */}
                        </Swiper>

                    </div>
                    :
                    null
                }
            </div>
        </div>
    </>
    )
}

const LeaderStat: React.FC<{ p: ILeaderStats, idx: TPlayerStatKeys }> = ({ p, idx }) =>
    <div className="player">
        <div className="rosterImage">
            <LazyLoadImage
                placeholderSrc={assetsBaseURL + 'placeholderPlayer.png'}
                alt="Memphis Grizzlies Player"
                onError={({ currentTarget }) => currentTarget.src = assetsBaseURL + 'placeholderPlayer.png'}
                src={rewriteCDNURL(`https://cdn.nba.com/headshots/nba/latest/1040x760/${p.playerId}.png`)}
            />
        </div>
        <h5> {p.lastName} </h5>
        <h6> {p.stat} {statDescription[idx]}</h6>
    </div>
