import { DateTime } from 'luxon'
import { baseURL, s3BaseURL } from "../../../../etc/settings"
import { IPageWrapper, ISCheduleDataRes, ItransformSpreadsheetData, StatDescriptionMap, THomeOrAwayOptions, TPlayerStatKeys, TScheduleState, TSeasonTypes } from "../../../../etc/types"
import { nbaSeasons } from "../components/etc/functions"

const staticData: IPageWrapper = {
  title: "Find Tickets",
  description: 'Check the Memphis Grizzlies schedule for game times and opponents for the season, as well as where to watch or radio broadcast the games on NBA.com',
  pageImage: baseURL + '',
  pageLink: baseURL + 'schedule'
}

export const assetsBaseURL = s3BaseURL + "assets/custom_pages/schedule/"

export default staticData

export const apiBaseURL = "https://d1uex6iw1vhj0r.cloudfront.net/"

export const googleSpreadsheetURL = "https://sheets.googleapis.com/v4/spreadsheets/12r-C7OTDXUyP7Gm9NW-M56QyFnnrJgRXKa5j3q-jOGk/values/prod?alt=json&key=AIzaSyCFSrkNO61TWdRB6tO6TpH_fxa3D4Ldle4"

//Data for Google Spreadsheet https://docs.google.com/spreadsheets/d/12r-C7OTDXUyP7Gm9NW-M56QyFnnrJgRXKa5j3q-jOGk/edit#gid=0
export const metaDataTemplate: ItransformSpreadsheetData = {
  'date': '',
  'time': '',
  'opponent': '',
  'city': '',
  'team': '',
  'ticket': '',
  'giveaways': '',
  'themenight': '',
  'sponsorLogo': '',
  'uniform': {
    'title': "",
    'link': ""
  }
}

//Broadcaster logos and keys (image names from S3 bucket)
export const getBroadcasters = (): Map<any, any> => {

  const haystack = new Map()

  haystack
    .set('ESPN', 'ESPN_wordmark.png')
    .set('ESPN LA 710/KWKW (S)', 'ESPN_Radio_logo.png')
    .set('ESPN Radio', 'ESPN_Radio_logo.png')
    .set('ESPN 92.9FM/680AM', 'MEM_929ESPN_black.png')
    .set('NBA TV', 'NBA_TV.png')
    .set('TNT', 'TNT_(TV_Channel).png')
    .set('Spectrum SportsNet', 'Spectrum_Sportsnet_logo.png')
    .set('Bally Sports SE-MEM', 'ballyballyplus.jpg')
    .set('SiriusXM', 'SiriusXMLogo.jpg')
    .set('FanDuel Sports Network - Southeast - Memphis','fd_lg_hr_rgb_blu_07_26_24.png')

  return haystack

}

//For Broadcasters
export const allowedBroadcastScopes = (providerKeyToRemove: string): Map<any, any> => {

  const haystack = new Map()

  haystack
    .set('natl', 'natl')
    .set('away', 'away')
    .set('home', 'home')

  //For home games we remove the away scope and for road games we remove the home scope based on if we are home or away. Natl will always show
  haystack.delete(providerKeyToRemove)

  return haystack

}

export const initialScheduleState: TScheduleState = {
  upcomingOrPast: true,
  year: nbaSeasons()[0],
  weekDaysChecked: new Map(),
  filteredScheduleData: [],
  selectedTeam: "all",
  homeOrAwayGame: "all",
  seasonTypes: "all",
  activeVideo: -1,
  firstGameId: 0,
  googleSpreadsheetData: [],
  monthsChecked: new Map(),
  dateRange: "",
  teamList: [],
  offersChecked: new Map(),
  layoutType: 'schedule'
}


//fluent UI structure
export const daysOfWeek = [
  { key: 7, text: "Weekends" },
  { key: 0, text: "Monday" },
  { key: 1, text: "Tuesday" },
  { key: 2, text: "Wednesday" },
  { key: 3, text: "Thursday" },
  { key: 4, text: "Friday" },
  { key: 5, text: "Saturday" },
  { key: 6, text: "Sunday" },
]

//Extracting the selected label for Days of The Week
export const extractSelectedDay = (haystack: Map<any, any>) => {

  const output = "all"

  const satisfies = (currentValue: number) => haystack.get(currentValue)
  if ([5, 6, 7].every(satisfies)) {
    return "Weekends"
  }

  const iterator1 = haystack.values();

  while (true) {
    const result = iterator1.next().value
    if (undefined === result) {
      break
    }
    return daysOfWeek.filter(d => d.key === result)[0].text
  }
  return output
}

export const extractSelectedMonth = (haystack: Map<any, any>) => {

  const output = "all"

  const iterator1 = haystack.values();

  while (true) {
    const result = iterator1.next().value
    if (undefined === result) {
      break
    }
    return monthsOptions.filter(d => d.key === result)[0].text
  }
  return output
}


export const offers = [
  { key: 0, text: "Giveaways" },
  { key: 1, text: "Theme Nights" },
]

export const extractSelectedOffer = (haystack: Map<any, any>) => {

  const output = "all"

  const iterator1 = haystack.values();

  while (true) {
    const result = iterator1.next().value

    if (undefined === result) {
      break
    }
    return offers.filter(d => d.key === result)[0].text
  }
  return output
}

export const extractSelectedSeason = (haystack:TSeasonTypes) => {

  const key = seasonOptions.map(([key, value]) => {
    return key
     })
if (key.includes(haystack)){
  return seasonOptions.filter(d => d[0] === haystack)[0][1]
} 
}



export const homeAwayOptions: THomeOrAwayOptions[] = ["all", "home", "away"]

//sorted by the order of our season (Oct-Dec)(Jan-April)
//TO DO: Explore a dynamics months list via the feed instead of a manual list. So that the months turn on when there are active games pulling in.
export const monthsOptions = [
  { key: 9, text: 'October' },
  { key: 10, text: 'November' },
  { key: 11, text: 'December' },
  { key: 0, text: 'January' },
  { key: 1, text: 'February' },
  { key: 2, text: 'March' },
  { key: 3, text: 'April' },
  // { key: 4, text: 'May' },
  // { key: 5, text: 'June' },
  // { key: 6, text: 'July' },
  // { key: 7, text: 'August' },
  // { key: 8, text: 'September' },
];

export const seasonTypeOptions = (): Map<TSeasonTypes, string> => {
  const haystack: Map<TSeasonTypes, string> = new Map()

  haystack
    .set("all", "All")
    // .set("preseason", "Preseason")
    .set("season", "Regular Season")
    .set("in-season", "Emirates NBA Cup")
    .set("play-in", "Play In")
    .set("playoff", "Playoff")
    // .set("summer-league", "Summer League")
    // .set("ist-final", "NBA Cup Final")

  return haystack
}

export const seasonOptions = [...seasonTypeOptions().entries()]

export const swiperBreakpoints = {
  578: {
    slidesPerView: 1,
    spaceBetween: 20,
  },
  769: {
    slidesPerView: 1,
  },
  1400: {
    slidesPerView: 2.4,
  },
}

export const buttonOverride = {
  background: "none",
  border: "none"
}

export const fetchSettings = { retries: 3, retryDelay: 1000 }

export const statDescription: StatDescriptionMap = {
  "points": "pts",
  "rebounds": "reb",
  "assists": "ast"
}