import { DateTime } from "luxon"
import { EHomeOrVersus, IDateTimeColumn } from "../../../../etc/types"
import { checkIfSummerLeague, formatNbaDate, formatTime, rewriteCDNURL } from "../components/etc/functions"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import React from "react"


const DateTimeColumn: React.FC<IDateTimeColumn> = (props) => {
    const { e: game } = props

    const isSummerLeagueGame = checkIfSummerLeague(game)

    const timeObject = DateTime.fromISO(game.gdte)

    //Logic is true for Season and if its summer summer leage it will return false because they are never played in Memphis
    let isHomeGame = game.ac === "Memphis" 
    if(isSummerLeagueGame) {
        isHomeGame = false
    } 

    // Detect opponent team property
    const opponentTeamKey: EHomeOrVersus =
        'mem' === game[EHomeOrVersus.h].ta.toLowerCase()
            ? EHomeOrVersus.v
            : EHomeOrVersus.h


    // Detect home team property
    const homeTeamKey: EHomeOrVersus =
        EHomeOrVersus.v == opponentTeamKey
            ? EHomeOrVersus.h
            : EHomeOrVersus.v

    const NBASeasonOpponentInfo = game[opponentTeamKey]

    const extraClassesPlayoff =
        (isHomeGame ? "home" : "away") + (game.seasonType ===
            "playoff"
            ? "Playoff"
            : "Regular")

    const extraClassesPlayoffLabel =
        `${isHomeGame ? 'home' : 'away'}Label${game.seasonType === 'playoff' ? 'Playoff' : 'Regular'}`

    const winOrLossTitle =
        Number(game[homeTeamKey].s) > Number(game[opponentTeamKey].s)
            ? "Win"
            : "Loss"

    return (
        <>
            {/* Game Info: Date, Time, Home/Away/Playoffs Labeling */}
            <div className={`
            gameInfo gDiv ${extraClassesPlayoff}`}>
                <h6 className="weekday"> {timeObject.weekdayShort} </h6>
                <h2> {formatNbaDate(game.gdte)}</h2>

                <h4 className="gTime">
                    {formatTime(game.etm, "America/New_York")}
                </h4>

                <h4 className={extraClassesPlayoffLabel}>
                    {isHomeGame && !isSummerLeagueGame ? "home" : "away"}
                </h4>

                {+game.st === 3 ?
                    <>
                        {/* score is formatted to always show Grizzlies on the right side, this was decided in order to match creative for the 23/24 season.
                    so the score will always be (opponent - grizzlies) regardless of home and away game.
                    */}
                        {isHomeGame ?
                            <h4 className="scoreWinLoss">
                                {game.v.s} - {game.h.s}
                            </h4>
                            :
                            <h4 className="scoreWinLoss">
                                {game.v.s} - {game.h.s}
                            </h4>
                        }
                        <h5 className="winOrLoss">
                            {winOrLossTitle}
                        </h5>
                    </>
                    : null
                }
            </div>

            {<div className="gameOpp gDiv">
                <div className="teamLogo">
                    <LazyLoadImage src={rewriteCDNURL(`https://cdn.nba.com/logos/nba/${NBASeasonOpponentInfo.tid}/primary/L/logo.svg`)} alt="Team Logo" />
                </div>
                <div className="teamName">
                    <h4>{NBASeasonOpponentInfo.tc} </h4>
                    <h5> {NBASeasonOpponentInfo.tn.toLowerCase()} </h5>
                </div>
                {/* For In Season Tournament */}
                {game.gameSubtype === "in-season" ?
                    <div className="sznTag">
                        <span className="lineBreak"></span>
                        {/* <h6 className="istLabel">{game.gameSubtype} Tournament </h6> */}
                        <h6 className="istLabel"> Emirates NBA Cup </h6>
                        <h6> {NBASeasonOpponentInfo.istGroup}</h6>
                    </div> : null}
            </div>
            }

        </>
    )
}

export default DateTimeColumn
